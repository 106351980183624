// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
// Slick
@import "~slick-carousel/slick/slick.css";

@import '~flatpickr/dist/flatpickr.min.css';
@import '~dragula/dist/dragula.min.css';
